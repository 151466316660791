import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons'

const Custom404 = () => {
	const router = useRouter()

	return (
		<Container style={{ textAlign: 'center', margin: '3rem auto' }}>
			<FontAwesomeIcon icon={faFaceFrown} className='fa-10x' color='lightgray' />
			<br /><br /><br />
			<h1 style={{ fontWeight: 700 }}>404</h1>
			<h4 style={{ color: 'darkgray' }}>Page not found</h4>

			<p style={{ fontSize: '14px' }}>The page you&apos;re looking for doesn&apos;t exist</p>
			<Button
				onClick={() => router.back()} style={{ color: 'white', fontWeight: 'bold' }}
			>Click here to go back</Button>
		</Container>
	)
}

export async function getStaticProps({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, ['common']))
		}
	}
}

export default Custom404
